import imgTelecom from './imagesFormation/Telecom.png'
import imgCpp from './imagesFormation/Cpp.png'
import imgJava from './imagesFormation/Java.png'
import imgWeb from './imagesFormation/Web.png'
import imgPython from './imagesFormation/Python.png'
import imgMatlab from './imagesFormation/Matlab.png'
import imgOpenCV from './imagesFormation/OpenCV.png'
import imgOpenGL from './imagesFormation/OpenGL.png'
import imgEclipse from './imagesFormation/Eclipse.png'
import imgVS from './imagesFormation/Visual.png'
import imgVSCode from './imagesFormation/VSCode.png'
import imgQT from './imagesFormation/QtCreator.png'
import imgUnreal from './imagesFormation/Unreal.png'
import imgUnity from './imagesFormation/Unity.png'
import imgCSharp from './imagesFormation/Csharp.png'
import imgRider from './imagesFormation/Rider.png'
import imgUQAC from './imagesFormation/UQAC.png'
import imgGitlab from './imagesFormation/Gitlab.png'
import imgPerforce from './imagesFormation/Perforce.png'
import { Box, Divider, Typography } from '@mui/material';
import customTheme from '../components/customTheme';

function Formation() {
  return (
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-evenly',
          [customTheme.breakpoints.up("mainpage")]: {flexDirection: 'row'},[customTheme.breakpoints.down("mainpage")]: {flexDirection: 'column'}}}>
          <Box sx={{display:'flex',flexDirection:'column',justifyContent:'start', alignItems:'center', flex: '1 1 0'}}>
            <Typography variant='h3'>
              Ingénieur en Informatique et Imagerie
            </Typography>
            <Typography variant='h5' sx={{marginBottom:'1svh'}}> 
              Télécom Saint-Etienne
            </Typography>
            <img src={imgTelecom} width={'65%'} style={{marginBottom: '2svh'}}></img>
            <Typography variant='h5' sx={{marginBottom:'1svh', alignSelf:'start', marginLeft: '1.5vw', textAlign: 'left'}}>
              Langages et outils
            </Typography>
            <Box sx={{display: 'flex', flexDirection:'row', width:'100%', justifyContent:'space-around', alignItems:'center'}}>
              <img src={imgCpp} className='imgTech'></img>
              <img src={imgJava} className='imgTech'></img>
              <img src={imgWeb} className='imgTech'></img>
              <img src={imgPython } className='imgTech'></img>
              <img src={imgMatlab} className='imgTech'></img>
              <img src={imgGitlab} className='imgTech'></img>
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row', width:'100%', justifyContent:'space-around', alignItems:'center',marginBottom:'2svh'}}>
              <img src={imgOpenGL} className='imgTech'></img>
              <img src={imgOpenCV} className='imgTech'></img>
              <img src={imgEclipse} className='imgTech'></img>
              <img src={imgVS} className='imgTech'></img>
              <img src={imgVSCode} className='imgTech'></img>
              <img src={imgQT} className='imgTech'></img>
            </Box>
            <Typography variant='h5' sx={{marginBottom:'1svh', alignSelf:'start', marginLeft: '1.5vw', textAlign: 'left'}}>
              Apprentissage du traitement d’image et de la détection et reconnaissance d’objets.
            </Typography>
            <Typography variant='h5' sx={{marginBottom:'1svh', alignSelf:'start', marginLeft: '1.5vw', textAlign: 'left'}}>
              Bases en Gestion de projets, Marketing et Economie.
            </Typography>
          </Box>
          <Divider flexItem fullWidth sx={{minWidth:'3px',minHeight:'3px', backgroundColor: 'rgba(254,254,254,0.1)',borderRadius:4}}/>
          <Box sx={{display:'flex',flexDirection:'column',justifyContent:'start', alignItems:'center', flex: '1 1 0', height:'100%'}}>
            <Typography variant='h3'>
              Maitrise en informatique (jeu vidéo)
            </Typography>
            <Typography variant='h5' sx={{marginBottom:'1svh'}}> 
              Université du Québec à Chicoutimi
            </Typography>
            <img src={imgUQAC} width={'65%'} style={{marginBottom: '2svh'}}></img>
            <Typography variant='h5' sx={{marginBottom:'1svh', alignSelf:'start', marginLeft: '1.5vw', textAlign: 'left'}}>
              Langages et outils
            </Typography>
            <Box sx={{display: 'flex', flexDirection:'row', width:'100%', justifyContent:'space-around', alignItems:'center',marginBottom:'2svh'}}>
              <img src={imgUnreal} className='imgTech'></img>
              <img src={imgUnity} className='imgTech'></img>
              <img src={imgCSharp} className='imgTech'></img>
              <img src={imgRider} className='imgTech'></img>
              <img src={imgPerforce} className='imgTech'></img>
            </Box>
            <Typography variant='h5' sx={{marginBottom:'1svh', alignSelf:'start', marginLeft: '1.5vw', textAlign: 'left'}}>
              Suivi de cours sur les spécificités du dévelopement de jeu vidéo et de leurs moteurs.
            </Typography>
            <Typography variant='h5' sx={{marginBottom:'1svh', alignSelf:'start', marginLeft: '1.5vw', textAlign: 'left'}}>
              Dévelopement de plusieurs jeux en équipes autogérées.
            </Typography>
          </Box>
        </Box>
  );
}

export default Formation;
