import imgReach from './imagesProjets/Reach.png'
import imgBaby from './imagesProjets/Baby.png'
import imgRA from './imagesProjets/RA.png'
import imgHoloV2 from './imagesProjets/HoloV2.png'
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function Projet({title,text1,text2,text3,par,imgSource,link,linkText,link2,linkText2})
{
  const [isMobile, setIsMobile] = React.useState(window.innerWidth<window.innerHeight);

  function handleWindowSizeChange() {
      setIsMobile(window.innerWidth<1060);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if(!isMobile) 
    return(
    <Box sx={{scrollSnapAlign:'start', display:'flex',flexDirection:par?'row':'row-reverse',
      justifyContent:'space-evenly',alignItems:'center',width:'100%',height:'100%'
    }}>
      <Box sx={{backgroundColor: 'rgba(254,254,254,0.1)',height:'90%',width:'40%',display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:'center',borderRadius: 4}}>
        <Typography variant='h2'>{title}</Typography>
        <img style={{maxWidth: '90%', maxHeight: '70%'}} src={imgSource}/>
      </Box>
      <Box sx={{width:'50%',display:'flex',flexDirection:'column',gap:'3svh', alignItems:'center'}} textAlign={'justify'}>
        <Typography variant='h5'>
          {text1}
        </Typography>
        <Typography variant='h5'>
          {text2}
        </Typography>
        <Typography variant='h5'>
          {text3}
        </Typography>
        <Link to={link} target="_blank" hidden={!link}><Button variant='contained'>
          <Typography variant='h5' color={'white'}>{linkText}</Typography>
        </Button></Link>
        <Link to={link2} target="_blank" hidden={!link2}><Button variant='contained'>
                  <Typography variant='h5' color={'white'}>{linkText2}</Typography>
        </Button></Link>
      </Box>
    </Box>
    )
  else if(par)
    return(
      <Box sx={{scrollSnapAlign:'start', display:'flex',flexDirection:'column',
        justifyContent:'space-evenly',alignItems:'center',width:'100%',height:'100%',
      }}>
        <Box sx={{backgroundColor: 'rgba(254,254,254,0.1)',height:'40%',width:'90%',display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:'center',borderRadius: 4}}>
              <Typography variant='h5'>{title}</Typography>
              <img style={{maxWidth: '90%', maxHeight: '70%'}} src={imgSource}/>
        </Box>
        <Box textAlign={'justify'} sx={{display:'flex',flexDirection:'row',
        justifyContent:'space-evenly',alignItems:'center',width:'100%',height:'60%'
        }}>
          <Typography variant='phoneProjects' sx={{width:'45%'}}>
            {text1}
          </Typography>
          <Box sx={{width:'45%',height:'90%',display:'flex',flexDirection:'column',gap:'3svh', alignItems:'center',justifyContent: 'space-evenly'}}>
              <Typography variant='phoneProjects'>
                {text2}
              </Typography>
              <Typography variant='phoneProjects'>
                {text3}
              </Typography>
              <Link to={link} target="_blank" hidden={!link}><Button variant='contained'>
                <Typography variant='phoneProjects' color={'white'}>{linkText}</Typography>
              </Button></Link>
              <Link to={link2} target="_blank" hidden={!link2}><Button variant='contained'>
                  <Typography variant='phoneProjects' color={'white'}>{linkText2}</Typography>
              </Button></Link>
          </Box>
        </Box>
      </Box>
    )
    else
      return(
        <Box sx={{scrollSnapAlign:'start', display:'flex',flexDirection:'column',
          justifyContent:'space-evenly',alignItems:'center',width:'100%',height:'100%',
        }}>
          <Box sx={{backgroundColor: 'rgba(254,254,254,0.1)',height:'40%',width:'90%',display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:'center',borderRadius: 4}}>
                <Typography variant='h5'>{title}</Typography>
                <img style={{maxWidth: '90%', maxHeight: '70%'}} src={imgSource}/>
          </Box>
          <Box textAlign={'justify'} sx={{display:'flex',flexDirection:'row',
          justifyContent:'space-evenly',alignItems:'center',width:'100%',height:'60%'
          }}>
            <Box sx={{width:'45%',height:'90%',display:'flex',flexDirection:'column',gap:'3svh', alignItems:'center',justifyContent: 'space-evenly'}}>
              <Typography variant='phoneProjects'>
                {text1}
              </Typography>
              <Typography variant='phoneProjects'>
                {text2}
              </Typography>
              <Link to={link2} target="_blank" hidden={!link2}><Button variant='contained'>
                  <Typography variant='phoneProjects' color={'white'}>{linkText2}</Typography>
              </Button></Link>
            </Box>
            <Box sx={{width:'45%',height:'90%',display:'flex',flexDirection:'column',gap:'3svh', alignItems:'center',justifyContent: 'space-evenly'}}>
                <Typography variant='phoneProjects'>
                  {text3}
                </Typography>
                <Link to={link} target="_blank" hidden={!link}><Button variant='contained'>
                  <Typography variant='phoneProjects' color={'white'}>{linkText}</Typography>
                </Button></Link>
            </Box>
          </Box>
        </Box>
      )
}

function Projets() {
  return (
        <Box sx={{height:'100%',scrollSnapType:'y mandatory',overflow:'scroll'}}>
          <Projet title={"Hologame Version 2.0"}
                  text1={"Hologame est le produit principal de la petite entreprise pour laquelle j'ai effectué mon stage de fin d'études. Il s'agit d'un jeu de tir en réalité virtuelle pour les centres de divertissement."+
                        "La version originale avait été créée lors d'un projet étudiant et bien que fonctionnel, le projet source était peu organisé et l'ajout de nouvelles fonctionnalités y était de plus en plus ardu."+
                        "Je me suis donc vu confié le développement d'une version 2.0 a depuis zéro. Cette version apporte également une plus grande facilité d'utilisation pour les clients."}
                  text2={"Cette version a été dévellopé sous Unity3D de novembre 2023 à avril 2024 et est toujours mise à jour. Je me charge en paralèlle du développement des outils de l'entreprise."}
                  text3={"Je développe ce projet dans le rôle rôle de Lead Developer. Je me suis chargé de l'implémentation de la plupart dans des fonctionnalités centrales et supervise l'architecture des nouvelles fonctionnalités."}
                  par={true}
                  imgSource={imgHoloV2}
                  link={'https://hologame.io/'}
                  linkText={"Site de l'entreprise"}/>
          <Projet title={"Retraite Anticipée"}
                  text1={"Retraite Anticipée est un jeu d'infiltration et puzzle en 3D vue du dessus dans lequel on incarne"+
                        "un homme agé souhaitant s'échapper de son bureau afin de ne pas y rester pour toujours."}
                  text2={"Il est développé dans la cadre de ma dernière année d’études sur Unreal Engine 5, dans un groupe de 5 personnes en mai et juin 2023."}
                  text3={"J'ai participé avec mon équipe au débats sur le game design initial du jeu, puis j'y ai joué le rôle de programmeur généraliste,"+
                        "en codant nottament les IA des ennemis, la gestion des conditions de fin de niveau et partie, ainsi que diverses mécaniques."}
                  par={false}
                  imgSource={imgRA}
                  link={'https://www.youtube.com/watch?v=Q80SzjfmRSo'}
                  linkText={"Trailer du jeu"}
                  link2={'https://clement-bourlet.itch.io/retraite-anticipee'}
                  linkText2={"Lien itch.io du jeu"}/>
          <Projet title={"Reach the station"}
                  text1={"Reach the station est un mini-jeu dans lequel on contrôle un vaisseau spatial naviguant dans un champ d’astéroide afin d’atteindre une station le plus rapidement possible."+
                        "un homme agé souhaitant s'échapper de son bureau afin de ne pas y rester pour toujours."}
                  text2={"Le contrôle se fait via une caméra par détections des mains du joueur."}
                  text3={"J'y ai réalisé la gestion des scènes et objets 3D et des collisions, ainsi que la reconnaissance des mains du joueur."}
                  par={true}
                  imgSource={imgReach}
                  link={'https://www.youtube.com/watch?v=dxZ85f9qYyc'}
                  linkText={"Vidéo de démonstration"}/>
          <Projet title={"Logiciel d'arbitrage vidéo pour babyfoot"}
                  text1={"Ce logiciel permet l'arbitrage d'un match de babyfoot à partir d'une vidéo en format avi. Il enregistre les moments des buts, gamelles et sorties et permet de les visualiser sous la forme d'une compilation."}
                  text2={"Il a été développé afin d’évaluer mes compétences acquises en tritement d'image et en dévellopement Java durant ma formation à Télécom Saint-Etienne, en équipe de 5."}
                  text3={"J'y ai réalisé la détéction de la balle et des différents événements et la documentation. J'ai également eu un rôle polivalent d'aide à la résolution de bugs lorsque mes camarades étaient bloqués."}
                  par={false}
                  imgSource={imgBaby}/>
        </Box>
  );
}

export default Projets;
