import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Link, useLocation } from "react-router-dom";
import imgDL from './Download.png'
import { Typography } from '@mui/material';
import customTheme from './customTheme';

const pages = ['Acceuil', 'Projets', 'Formation'];

function PCAppBar() {

  const onDownloadButtonClick = () => {
    fetch('CV.pdf').then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'CV.pdf';
            alink.click();
        })
    })
}

  return (
    <AppBar position="sticky" sx={{maxWidth: 0.95, paddingX: '2vw', borderRadius: 3, gap: '2vw', height: '64px', display: 'flex', justifyContent: 'center'}}>
      <Box sx={{ width:'100%', display: 'flex'}}>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: '2vw'}}>
          {pages.map((page) => (
            <PageDependentButton page={page} key={page}/>
          ))}
        </Box>
        <Button 
          variant="contained"
          sx={{borderRadius: 3}}
          onClick={onDownloadButtonClick}
        >
          <Box sx={{ display: 'flex',
            [customTheme.breakpoints.up("sm")]: {width:24},[customTheme.breakpoints.down("sm")]: {width:14}}}>
            <img src={imgDL} style={{width: '100%'}}/>
          </Box>
          <Typography variant='responsiveMenu'>
            CV
          </Typography>
        </Button>
      </Box>
    </AppBar>
  );
}

function PageDependentButton({page}){
  const location = useLocation();
  const [loc,setLoc] = React.useState('/')
  
  let path = "/"
  if(page!=="Acceuil"){
    path = "/" + page
  }

  React.useEffect(() => {
    // execute on location change
    if(location.pathname !== loc)
    {
        setLoc(location.pathname)
    }
    console.log(location.pathname)
  }, [location]);
    
  return(
    <Link to={path} style={{textDecoration: 'none'}}>
      <Button
        key={page}
        color = "secondary"
        variant={(loc===path)?"contained":'auto'}
        sx={{display: 'block' , borderRadius: 3}}
      >
        <Typography variant='responsiveMenu'>
        {page}
        </Typography>
      </Button>
    </Link>
  );
}

export default PCAppBar;