import photo from './photoT.png';
import logoMail from './imagesContact/Mail.png';
import logoLinkedin from './imagesContact/Linkedin.png';
import logoGitHub from './imagesContact/github.svg'
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import customTheme from '../components/customTheme';

function Acceuil() {
  return (
    <div className="Acceuil">
        <Box sx={{display: 'flex',justifyContent: 'space-evenly', alignItems: 'center',
          [customTheme.breakpoints.up("mainpage")]: {flexDirection: 'row'},[customTheme.breakpoints.down("mainpage")]: {flexDirection: 'column'},
          width: '100%',marginY: '5%'}}>
          
          <img src={photo} style={{maxWidth:'90%'}}/>
          <Box sx={(theme)=>({display: 'flex', flexDirection: 'column', 
            [theme.breakpoints.up("sm")]: {maxWidth: '45%'},[theme.breakpoints.down("sm")]: {maxWidth: '90%'}})}>
            <p className='Acceuil-Tx'>
              Bienvenue, je suis 
            </p>
            <p className='Acceuil-Tx2'>
              Clément Bourlet
            </p>
            <p className='Acceuil-Tx'>
              Ingénieur en informatique
            </p>
            {/*
            <p className='Acceuil-Tx4'>
              Développeur de jeux vidéo
            </p>
            */}
          </Box>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-evenly',
          [customTheme.breakpoints.up("mainpage")]: {flexDirection: 'row'},[customTheme.breakpoints.down("mainpage")]: {flexDirection: 'column'}}}>
          <Link to={"mailto:clement.bourlet@telecom-st-etienne.fr"} style={{textDecoration: 'none'}}>
            <Button className='Mail' sx={{ display: 'flex', color:'white'}}>
              <img src={logoMail} style={{height: '8svh', marginRight: '1vw'}}></img>       
              <Typography variant='h6'>clement.bourlet@telecom-st-etienne.fr</Typography>
            </Button>
          </Link>
          <Link to={"https://www.linkedin.com/in/cl%C3%A9ment-bourlet-9677601bb/"} target="_blank" style={{textDecoration: 'none'}}>
            <Button className='Mail' sx={{ display: 'flex', color:'white'}}>
              <img src={logoLinkedin} style={{height: '8svh', marginRight: '1vw'}}></img>
              <Typography variant='h6'>Mon linkedin</Typography>
            </Button>
          </Link>
          <Link to={"https://github.com/ClemBrlt"} target="_blank" style={{textDecoration: 'none'}}>
            <Button className='Mail' sx={{ display: 'flex', color:'white'}}>
            <img src={logoGitHub} style={{height: '7svh', marginRight: '1vw', marginBottom: '5px', marginTop: '5px'}}></img>
            <Typography variant='h6'>Mon Github</Typography>
            </Button>
          </Link>
        </Box>
    </div>
  );
}

export default Acceuil;
