import { createTheme, responsiveFontSizes } from "@mui/material";
import inder from '../Inder-Regular.ttf'

const customTheme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          mainpage: 770,
          md: 900,
          project: 1060,
          lg: 1200,
          xl: 1536,
        },
    },

    palette: {
        primary: {
            main: 'rgba(254,254,254,0.1)',
        },
        secondary: {
            main: 'rgba(0,0,0,0.5)',
        },
    },

    typography: {
        fontFamily: ['"Inder"', 'Open Sans'].join(','),
        responsiveMenu: {
            fontSize: '1.2rem',
        },
        phoneProjects: {
            fontSize: '0.75em',
        },
    },

    overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [inder],
          },
        },
      },
      
});

customTheme.typography.responsiveMenu = 
{
    color: 'white',
    [customTheme.breakpoints.up("sm")]: {
        fontSize: '20px'
    },
    [customTheme.breakpoints.down("sm")]: {
        fontSize: '12px'
    }
}

export default customTheme;