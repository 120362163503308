import { Route, Routes, useLocation } from 'react-router-dom';
import React from 'react';
import Acceuil from './pages/Acceuil';
import Formation from './pages/Formation';
import Projets from './pages/Projets';
import PCAppBar from './components/MenuBar';
import { ThemeProvider } from '@emotion/react';
import customTheme from './components/customTheme';
import { Box } from '@mui/material';
import './App.css';

function App() {
    const bodyRef = React.useRef(null)
    const [loc,setLoc] = React.useState('/')
    const location = useLocation();

    React.useEffect(() => {
        // execute on location change
        if(location.pathname !== loc && bodyRef.current)
        {
            setLoc(location.pathname)
            bodyRef.current.scrollTop = 0;
        }

    }, [location]);

    return(
    <div className="App">
        <ThemeProvider theme={customTheme}>
        <header className="App-header">
            <PCAppBar currentPage={"Acceuil"}/>
        </header>
        <div className="App-body">
        <Box ref={bodyRef} sx={{mb: 2, display: "flex",flexDirection: "column", height: '100%',overflowX: "hidden", overflowY: "scroll"}}>
            <Routes>
                <Route index element={<Acceuil />}/>
                <Route path="/Projets" element={<Projets />} />
                <Route path="/Formation" element={<Formation />} />
            </Routes>
        </Box>
        </div>
        </ThemeProvider>
    </div>
    );
}

export default App;